<template>
  <Transition name="fade" mode="out-in">
    <div
      class="prevent-select sidenav bg-white h-100"
      :class="computedMenuClass"
    >
      <div class="text-center">
        <img
          class="icons"
          src="/usfc_monogram.svg"
          style="width: 35%; margin: 35px 0 10% 0"
        />
      </div>

      <!-- NAV LINK STARTS -->
      <ul class="m-2" style="vertical-align: center">
        <!-- DASHBOARD -->
        <router-link
          to="/dashboard"
          :class="{ active: this.$route.name == 'Dashboard' }"
          id="dashboard"
        >
          <img
            src="/icons/all/twotone/home.svg"
            style="position: relative; top: -3px"
            class="mx-3"
          />Dashboard</router-link
        >
        <!-- RECEIPTS -->
        <router-link
          to="/receipts"
          :class="{ active: this.$route.name == 'Receipts' }"
          id="dashboard"
        >
          <img
            src="/icons/all/twotone/map-1.svg"
            style="position: relative; top: -3px"
            class="mx-3"
          />Receipts</router-link
        >
        <!-- CHECK RATES -->
        <router-link
          v-if="this.$store.state.userData.access_level == 2"
          to="/check-rates"
          :class="{ active: this.$route.name == 'Check Rates' }"
          id="dashboard"
        >
          <img
            src="/icons/all/twotone/check.svg"
            style="position: relative; top: -3px"
            class="mx-3"
          />Check Rates</router-link
        >
        <!-- RATES -->
        <li
          v-if="this.$store.state.userData.access_level > 2"
          id="rates"
          @click="toggleMenu('rates')"
          :class="{
            active: [
              'Public Rates',
              'Client Rates',
              'Request Change',
              'Request Change ',
              'Client Rates ',
            ].includes(this.$route.name),
          }"
          class="nav-parent"
        >
          <img
            src="/icons/all/twotone/check.svg"
            style="position: relative; top: -3px"
            class="mx-3"
          />
          Rates
          <img
            class="parent-arrow"
            src="/icons/all/twotone/arrow-right-1.svg"
            style="position: relative; top: 3px; float: right"
          />
        </li>
        <Transition name="fade">
          <!-- RATES CHILDS -->
          <ul v-if="getActiveMenu == 'rates'" class="nav-child">
            <router-link
              @click="
                this.$store.state.app.managerForm.currentComp = 'public-rates'
              "
              to="/rates/public"
              :class="{ active: this.$route.name == 'Public Rates' }"
              class="child-menu"
              id="dashboard"
              >Public Rates</router-link
            >
            <router-link
              @click="
                this.$store.state.app.managerForm.currentComp = 'client-rates'
              "
              to="/rates/client"
              :class="{
                active: ['Client Rates', 'Client Rates '].includes(
                  this.$route.name
                ),
              }"
              class="child-menu"
              id="dashboard"
              >Client Rates</router-link
            >
            <router-link
              @click="
                this.$store.state.app.managerForm.currentComp = 'change-rates'
              "
              v-if="
                this.$store.state.userData.access_level == 3 ||
                this.$store.state.userData.access_level == 5
              "
              to="/rates/request"
              :class="{
                active:
                  this.$route.name == 'Request Change' ||
                  this.$route.name == 'Request Change ',
              }"
              class="child-menu"
              id="dashboard"
              >Request Change</router-link
            >
          </ul>
        </Transition>
        <!-- BOOKINGS (CLient) -->
        <router-link
          v-if="[5, 4, 2].includes(this.$store.state.userData.access_level)"
          to="/bookings"
          :class="{ active: this.$route.name == 'Bookings' }"
          id="bookings"
        >
          <img
            src="/icons/all/twotone/calendar-tick.svg"
            style="position: relative; top: -3px"
            class="mx-3"
          />Bookings
        </router-link>
        <!-- Recipients (CLient) -->
        <router-link
          v-show="this.$store.state.userData.access_level == 2"
          to="/recipients"
          :class="{ active: this.$route.name == 'Recipients' }"
          id="recipients"
        >
          <img
            src="/icons/all/twotone/buildings.svg"
            style="position: relative; top: -3px"
            class="mx-3"
          />Recipients</router-link
        >
        <!-- Manages -->
        <li
          v-if="this.$store.state.userData.access_level == 5"
          id="manages"
          @click="toggleMenu('manages')"
          :class="{
            active: ['users', 'references'].includes(
              this.$route.path.split('/')[2]
            ),
          }"
          class="nav-parent"
        >
          <img
            src="/icons/all/twotone/setting-4.svg"
            style="position: relative; top: -3px"
            class="mx-3"
          />
          Manages
          <img
            class="parent-arrow"
            src="/icons/all/twotone/arrow-right-1.svg"
            style="position: relative; top: 3px; float: right"
          />
        </li>
        <Transition name="fade">
          <!-- MANAGES CHILDS -->
          <ul v-if="getActiveMenu == 'manages'" class="nav-child">
            <router-link
              @click="this.$store.state.app.managerForm.currentComp = 'users'"
              to="/manage/users"
              :class="{ active: this.$route.path.split('/')[2] == 'users' }"
              class="child-menu"
              id="dashboard"
              >Users
            </router-link>
            <router-link
              @click="
                this.$store.state.app.managerForm.currentComp = 'references'
              "
              to="/manage/references"
              :class="{ active: this.$route.name == 'References' }"
              class="child-menu"
              id="dashboard"
              >References</router-link
            >
          </ul>
        </Transition>

        <!-- Manages -->
        <li
          v-if="this.$store.state.userData.access_level == 5"
          id="promotions"
          @click="toggleMenu('promotions')"
          :class="{
            active: ['promo', 'ads'].includes(this.$route.path.split('/')[2]),
          }"
          class="nav-parent"
        >
          <img
            src="/icons/all/twotone/setting-4.svg"
            style="position: relative; top: -3px"
            class="mx-3"
          />
          Promotion
          <img
            class="parent-arrow"
            src="/icons/all/twotone/arrow-right-1.svg"
            style="position: relative; top: 3px; float: right"
          />
        </li>
        <Transition name="fade">
          <!-- MANAGES CHILDS -->
          <ul v-if="getActiveMenu == 'promotions'" class="nav-child">
            <router-link
              @click="this.$store.state.app.managerForm.currentComp = 'promo'"
              to="/promotions/promo"
              :class="{ active: this.$route.path.split('/')[2] == 'promo' }"
              class="child-menu"
              id="dashboard"
              >Promo
            </router-link>
            <router-link
              @click="this.$store.state.app.managerForm.currentComp = 'ads'"
              to="/promotions/ads"
              :class="{ active: this.$route.name == 'ads' }"
              class="child-menu"
              id="dashboard"
              >Ads</router-link
            >
          </ul>
        </Transition>

        <!-- Reports -->
        <li
          v-if="permissionCheck('report')"
          id="reports"
          @click="toggleMenu('reports')"
          :class="{
            active: ['Receipt', 'SLA', 'Sales Performance'].includes(
              this.$route.name
            ),
          }"
          class="nav-parent"
        >
          <img
            src="/icons/all/twotone/book-1.svg"
            style="position: relative; top: -3px"
            class="mx-3"
          />
          Reports
          <img
            class="parent-arrow"
            src="/icons/all/twotone/arrow-right-1.svg"
            style="position: relative; top: 3px; float: right"
          />
        </li>
        <Transition name="fade">
          <!-- REPORTS CHILDS -->
          <ul
            v-if="getActiveMenu == 'reports' && permissionCheck('report')"
            class="nav-child"
          >
            <router-link
              v-if="permissionCheck('report')"
              @click="
                this.$store.state.app.managerForm.currentComp = 'report-receipt'
              "
              to="/reports/receipt"
              :class="{ active: this.$route.name == 'Receipt' }"
              class="child-menu"
              id="dashboard"
              >Receipt</router-link
            >
            <router-link
              v-if="permissionCheck('report')"
              @click="this.$store.state.app.managerForm.currentComp = 'sla'"
              to="/reports/sla"
              :class="{ active: this.$route.name == 'SLA' }"
              class="child-menu"
              id="dashboard"
              >SLA</router-link
            >
            <router-link
              v-if="this.$store.state.userData.access_level > 4"
              @click="
                this.$store.state.app.managerForm.currentComp =
                  'Sales Performance'
              "
              to="/reports/sales-performance"
              :class="{ active: this.$route.name == 'Sales Performance' }"
              class="child-menu"
              id="dashboard"
              >Sales Performance</router-link
            >
            <router-link
              v-if="this.$store.state.userData.access_level > 4"
              @click="
                this.$store.state.app.managerForm.currentComp =
                  'Transporter Performance'
              "
              to="/reports/transporter-performance"
              :class="{ active: this.$route.name == 'Transporter Performance' }"
              class="child-menu"
              id="dashboard"
              >Transporter Perf...</router-link
            >
            <router-link
              v-if="this.$store.state.userData.access_level > 4"
              @click="
                this.$store.state.app.managerForm.currentComp =
                  'Gasoline Report'
              "
              to="/reports/gasoline-report"
              :class="{ active: this.$route.name == 'Gasoline Report' }"
              class="child-menu"
              id="dashboard"
              >Gasoline Report</router-link
            >
          </ul>
        </Transition>
        <!-- <router-link to="/bookings" v-if="this.$store.state.userData.access_level == 4" :class="{ active: this.$route.name == 'bookings' }" id="dashboard"> <img src="/icons/all/twotone/calendar-tick.svg" style="position: relative; top: -3px" class="mx-3" />Bookings</router-link> -->
      </ul>
    </div>
  </Transition>
  <button class="hamburger-button" @click="toggleNav">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M2.75 12.25h10.5m-10.5-4h10.5m-10.5-4h10.5"
      />
    </svg>
  </button>
</template>

<script>
import axios from "axios";
export default {
  name: "NavComponent",
  props: {},
  data() {
    return {
      activeMenu: "manageusers",
      managerChild: ["manageusers", "managerefs", "managerates"],
      reportsChild: ["reportsreceipt", "reportssla"],
      pendingBooking: 0,
      isOpenMenu: false,
    };
  },
  computed: {
    getActiveMenu() {
      return this.activeMenu;
    },
    reportsParent() {
      return this.reportsChild;
    },
    computedMenuClass() {
      return window.innerWidth <= 768
        ? this.isOpenMenu
          ? "open"
          : "close"
        : "";
    },
  },
  methods: {
    async fetchPendingBooking() {
      let token = JSON.parse(localStorage.getItem("token"));
      this.$store.state.app.managerForm.noResult = false;
      this.$store.state.app.managerForm.formLoading = true;
      let access = this.$store.state.userData.access_level;

      let path;
      if (access >= 4) path = "all";
      else if (access === 2) path = "by-client";
      else path = "all";

      axios
        .get(
          this.$store.state.app.apiBasepath +
            `/booking-receipt/${path}?filter=pending`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          console.log(res.data.count);
          this.pendingBooking = res.data.count;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    toggleMenu(val, menu = "", submenu = "") {
      if (this.activeMenu == val) {
        this.activeMenu = "";
      } else {
        this.activeMenu = val;
      }
      this.$emit("menu", menu);
      this.$emit("submenu", submenu);
    },
    permissionCheck(navId) {
      if (navId === "report") {
        if ([5, 3, 2].includes(this.$store.state.userData.access_level))
          return true;
        else return false;
      }
    },
    toggleNav() {
      this.isOpenMenu = !this.isOpenMenu;
      console.log(this.isOpenMenu);
    },
  },
  mounted() {
    this.fetchPendingBooking();
    // setInterval(() => {
    //   this.fetchPendingBooking();
    // }, 1000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.hamburger-button {
  position: fixed;
  bottom: 0;
  right: 20px;
  transform: translateY(-50%);
  background: #3699ff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 0 5px 5px 0;
  z-index: 99999;
}
@media screen and (min-width: 768px) {
  .hamburger-button {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .hamburger-button {
    display: block;
  }
}
.open {
  transform: translateX(0);
}
.close {
  transform: translateX(-100%);
}
/* 
  * {
    
    border: 1px solid red;

  } */

ul {
  max-height: 80vh;
  overflow: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3699ff;
    border-radius: 6px;
  }
}
.sidenav {
  position: fixed;
  left: 0;
  width: 310px;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.form {
  margin-top: 15vh;
  padding: 10%;
  text-align: left;
}

.right-text {
  text-align: right;
}
.child-menu {
  margin: 0;
  padding-left: 65px;
}

h3 {
  margin: 40px 0 0;
}
.sidenav > ul:first-of-type {
  list-style-type: none;
  text-align: left;
  padding: 15px 20px;
  width: 90%;
}

li {
  display: block;
  margin: 10px 0;
  padding: 10px;
  font-size: 2vh;
  opacity: 0.4;
  cursor: pointer;
  vertical-align: middle;
  line-height: 24px;
}

li.active {
  opacity: 1;
  background: rgba(54, 153, 255, 0.1);
  border-radius: 6px;
  filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg)
    brightness(107%) contrast(101%);
}

li:hover {
  opacity: 1;
  background: rgba(54, 153, 255, 0.1);
  border-radius: 6px;
  filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg)
    brightness(107%) contrast(101%);
}

li.active.nav-parent {
  background-color: transparent;
}

li.active .parent-arrow {
  transform: rotate(90deg);
}

a {
  display: block;
  margin: 10px 0;
  padding: 10px;
  font-size: 2vh;
  opacity: 0.4;
  cursor: pointer;
  vertical-align: middle;
  line-height: 24px;
}

a.active {
  opacity: 1;
  background: rgba(54, 153, 255, 0.1);
  border-radius: 6px;
  filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg)
    brightness(107%) contrast(101%);
}

a:hover {
  opacity: 1;
  background: rgba(54, 153, 255, 0.1);
  border-radius: 6px;
  filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg)
    brightness(107%) contrast(101%);
}

a.active.nav-parent {
  background-color: transparent;
}

a.active .parent-arrow {
  transform: rotate(90deg);
}

.label-float {
  position: relative;
  padding-top: 13px;
}

input {
  width: 100%;
}

.label-float input {
  border: 1px solid lightgrey;
  background-color: whitesmoke;
  border-radius: 5px;
  outline: none;
  min-width: 250px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  top: 10px;
}

.label-float input:focus {
  border: 2px solid #3951b2;
  background-color: transparent;
}

.label-float input::placeholder {
  color: black;
}

.label-float label {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 8px);
  left: 15px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  background-color: whitesmoke;
  padding: 5px;
  box-sizing: border-box;
}

.label-float input:required:invalid + label {
  color: red;
}
.label-float input:focus:required:invalid {
  border: 2px solid red;
}
.label-float input:required:invalid + label:before {
  content: "*";
}
.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label {
  font-size: 13px;
  top: 10px;
  color: #3951b2;
  background-color: transparent;
}
</style>
