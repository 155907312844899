<template>
  <div class="wrapper-content">
    <div class="d-inline">
      <input
        id="searchbar"
        @input="search()"
        v-model="this.$store.state.app.rateForm.public.search"
        class="searchbar fa mt-3"
        type="text"
        style="
          padding: 1% 17% 1% 1% !important;
          font-family: Open Sans, FontAwesome;
        "
        placeholder="&#xF002;  search.. "
      />
      <span class="mx-3">Search by :</span>
      <div
        @click="selectFilter = !selectFilter"
        @mouseleave="closeFilter()"
        class="btn btn-usfc-outline mx-3 px-2 position-absolute text-start"
        :class="{ opened: selectFilter }"
      >
        <div>
          <b>
            <span style="float: left">{{ activeFilter }}</span>
            <span class="ms-3" v-if="!selectFilter" style="float: right"
              >&#x25BC;</span
            >
            <span v-if="selectFilter" class="ms-3" style="float: right"
              >&#x25B2;</span
            >
          </b>
        </div>

        <div
          v-if="selectFilter"
          class="btn category-dropdown p-0 text-start d-block"
        >
          <div
            v-for="(filter, index) in filters"
            :key="index"
            class="dropdown-item"
            :class="{ active: activeFilter == filters[index] }"
            @click="changeFilter(filter)"
          >
            <span v-if="filter != activeFilter">{{ filter }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      @click="this.$store.state.app.openModal = 'publicRateCreate'"
      v-if="this.$store.state.app.rateForm.public.selects.length == 0"
      style="float: right"
    >
      <button
        v-if="
          this.$store.state.userData.access_level == 5 ||
          this.$store.state.userData.access_level == 4 ||
          this.$store.state.userData.access_level == 3
        "
        class="btn btn-usfc mt-3"
        style="border-radius: 6px"
      >
        <span>Create Public Rate</span> <span> + </span>
      </button>
    </div>
    <div v-else style="float: right">
      <button
        @click="this.$store.state.app.rateForm.public.selects = []"
        class="btn cancel mx-2"
        style="border-radius: 6px"
      >
        <span class="px-4" style="font-weight: 300">Cancel</span>
      </button>
      <button
        @click="deleteMany()"
        class="btn red-filter"
        style="border-radius: 6px"
      >
        <span
          ><img
            src="/icons/all/twotone/trash.svg"
            style="
              padding-right: 10px;
              transform: scale(0.9);
              position: relative;
              top: -2px;
            "
            class="blue-filter"
          />Delete ({{
            this.$store.state.app.rateForm.public.selects.length
          }})</span
        >
      </button>
    </div>
  </div>

  <div class="row my-3">
    <!-- <div class="d-inline">
      <span class="mx-2" style="cursor: pointer"><img src="/icons/custom/picker-deselect.svg" /></span>
      <span class="mx-4"> &emsp; </span>
      <span class="mx-2" style="cursor: pointer">
        Name
        <iconGroup class="blue-filter">
          <img src="/icons/all/twotone/arrow-up.svg" class="scaled" />
          <img src="/icons/all/twotone/arrow-down-1.svg" class="scaled" style="position: relative; left: -15px" />
        </iconGroup>
      </span>
    </div> -->
    <TableView />
  </div>
</template>

<script>
import TableView from "./TableView.vue";
import router from "@/router";
import axios from "axios";
/* eslint-disable */

export default {
  name: "ManagerComponent",
  props: {},
  components: { TableView },
  data() {
    return {
      sortType: "ASC",
      page: 1,
      refSelects: [],
      admins: [],
      allOrigin: [],
      loadForm: "signin",
      menu: "Manage",
      submenu: "Users",
      userAction: false,
      selectFilter: false,
    };
  },
  computed: {
    filters() {
      return this.$store.state.app.rateForm.public.filters.available;
    },
    activeFilter() {
      return this.$store.state.app.rateForm.public.filters.active;
    },
    getLoadForm() {
      return this.loadForm;
    },
    totalPages() {
      return Math.ceil(
        this.$store.state.app.rateForm.public.allRates.length / 10
      );
    },
    currentMenu() {
      return this.menu;
    },
    currentSubmenu() {
      return this.submenu;
    },
    isUserAction() {
      return this.userAction;
    },
  },
  mounted() {},
  methods: {
    preloadState() {
      this.$store.state.app.managerForm.noResult = false;
      this.$store.state.app.managerForm.formLoading = true;
    },
    isDataEmpty(axiosResponse) {
      if (axiosResponse.count == 0) {
        this.$store.state.app.managerForm.noResult = true;
        this.$store.state.app.managerForm.formLoading = false;
      } else {
        this.$store.state.app.managerForm.noResult = false;
        this.$store.state.app.managerForm.formLoading = false;
      }
    },
    changeFilter(changeto) {
      this.$store.state.app.rateForm.public.filters.active = changeto;
      document.getElementById("searchbar").focus();
    },
    closeFilter() {
      setTimeout(() => {
        this.selectFilter = false;
      }, 100);
    },
    deleteMany() {
      let token = JSON.parse(localStorage.getItem("token"));
      if (
        confirm(
          "Delete " +
            this.$store.state.app.rateForm.public.selects.length +
            " selected origin(s)?"
        )
      )
        axios
          .delete(this.$store.state.app.apiBasepath + "/rate/delete", {
            headers: { Authorization: `Bearer ${token}` },
            data: {
              selectedId: this.$store.state.app.rateForm.public.selects,
            },
          })
          .then((res) => {
            this.$store.state.app.rateForm.public.selects = [];
            router.replace("/dashboard");
            setTimeout(() => {
              router.replace("/rates/public");
            }, 1);
          })
          .catch((err) => {
            console.log(err);
          });
    },
    toggleModal() {
      this.$store.state.app.overlay.blur = true;
    },
    ascSort() {
      function compare(a, b) {
        if (a.rate < b.rate) return -1;
        if (a.rate > b.rate) return 1;
      }

      this.page = 1;
      this.sortType = "ASC";
      this.$store.state.app.rateForm.public.allRates.sort(compare);
      this.paginate();
    },
    descSort() {
      function compare(a, b) {
        if (a.rate < b.rate) return 1;
        if (a.rate > b.rate) return -1;
      }

      this.page = 1;
      this.sortType = "DESC";
      this.$store.state.app.rateForm.public.allRates.sort(compare);
      this.paginate();
    },
    search() {
      let token = JSON.parse(localStorage.getItem("token"));

      let path = "";

      if (
        this.$store.state.app.rateForm.public.filters.active == "Shipping Type"
      ) {
        path = "shipping_type";
      } else {
        path =
          this.$store.state.app.rateForm.public.filters.active.toLowerCase();
      }

      axios
        .get(
          this.$store.state.app.apiBasepath +
            "/rate/all?search=" +
            path +
            "&value=" +
            this.$store.state.app.rateForm.public.search.toLowerCase(),
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          this.$store.state.app.rateForm.public.allRates = res.data.rows;
          this.isDataEmpty(res.data);
          this.ascSort();
        })
        .catch((err) => {
          console.log("500: " + token);
        });
    },
    paginate() {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      for (let i = 0; i < this.totalPages; i++) {
        this.$store.state.app.rateForm.public.filteredRates[i] = JSON.parse(
          JSON.stringify(
            this.$store.state.app.rateForm.public.allRates.slice(
              i * 10,
              (i + 1) * 10
            )
          )
        );
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .wrapper-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .d-inline {
    display: flex !important;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .btn-usfc-outline {
    transform: translateY(0) translateX(0) !important;
  }
  .position-absolute {
    position: relative !important;
  }
}
.category-dropdown {
  position: relative;
}

.category-dropdown:active {
  position: relative;
  outline: none;
  border: 1px solid transparent;
}

.category-dropdown > .dropdown-item {
  line-height: 28px;
  background-color: white;
}

.dropdown-item:hover {
  color: #3699ff;
}

.dropdown-item.active {
  color: #3699ff;
  font-weight: 600;
}
.usfc-bg {
  background-color: #fbfbfb;
}

.btn {
  transition: all 0.15s ease;
}

.btn-usfc-outline {
  padding: 14px;
  text-align: left;
  background-color: white;
  color: #3699ff;
  transform: translateY(17px) translateX(-30px);
}

.btn-usfc-outline.opened {
  box-shadow: 0 0 2px 3px rgba(0, 0, 0, 0.05);
  outline: solid 1px rgba(0, 0, 0, 0.25);
  transform: translateY(17px) translateX(-20px);
}

.btn-usfc-outline:hover {
  background-color: white;
}

.btn-usfc-outline:focus {
  outline: solid 1px #3699ff;
  color: #3699ff;

  background-color: white;
}

.btn-usfc-outline:active {
  color: #3699ff;
  border: 1px solid transparent;
  background-color: white;
}
.btn-usfc {
  background-color: #3699ff;
  color: white;
  font-size: 0.9em;
  padding: 10px 15px;
}

.btn-usfc:hover {
  background-color: white;
  color: black;
  border: 1px solid #3699ff;
}

.scaled {
  transform: scale(0.8);
}

.searchbar {
  background-color: #fbfbfb;
  border: none;
  border-radius: 6px;
  padding: 1% 17% 1% 1%;
}

.searchbar::placeholder {
  /* Most modern browsers support this now. */
  color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  font-size: 0.9em;
}
.cancel:hover {
  font-weight: bold;
  border: solid 1px rgba(0, 0, 0, 0.2);
}

.cancel:active {
  font-weight: bold;
  border: solid 1px rgba(0, 0, 0, 1);
}

input.searchbar {
  border: solid 1px rgba(54, 153, 255, 0);
}

input.searchbar:focus {
  outline: none !important;
  border: solid 1px rgba(54, 153, 255, 0.5);
}

.red-filter {
  background: rgba(54, 153, 255, 0.1);
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.red-filter:hover {
  background: rgba(54, 153, 255, 0.25);
}

.blue-filter {
  filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg)
    brightness(108%) contrast(101%);
}
.profile-card {
  background-color: white;
  width: 7%;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
}

/* .profile-card:hover {
    border: solid 1px rgba(54, 153, 255, 0.25);
  } */

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.parent_arrow {
  transform: rotate(180deg);
}

.user-button {
  position: absolute;
  right: 3vw;
  top: 15vh;
  width: 12vw;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  text-align: left;
  border-radius: 6px;
}

ul {
  height: 5%;
}

li {
  text-align: center;
  display: inline;
  padding: 1% 0;
  margin: 0;
  width: 8%;
  font-weight: 400;
  cursor: pointer;
  vertical-align: middle;
}

li.active {
  opacity: 1;
  background: rgba(54, 153, 255, 0.1);
  border-radius: 6px;
  filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg)
    brightness(107%) contrast(101%);
}

li:hover {
  opacity: 1;
  background: rgba(54, 153, 255, 0.1);
  border-radius: 6px;
  filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg)
    brightness(107%) contrast(101%);
}

.sign-out-button {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.sign-out-button:hover {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.top-panel {
  height: 10vh;
}

.frame-content {
  min-height: 80vh;
}
.illustration {
  align-items: center;
  flex-wrap: wrap;
}

.vh-100 {
  height: 100vh;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.label-float {
  position: relative;
  padding-top: 13px;
}

input {
  border: 1px solid transparent;
}

select {
  border: 1px solid transparent;
}

.btn {
  font-size: small;
  min-width: 90px;

  border-radius: 6px;
  padding: 10px 25px;
}

.btn.btn-cancel {
  border-radius: 6px;
  padding: 10px 25px;
}

.btn.btn-cancel:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.btn.btn-cancel:active {
  border: 1px solid rgba(0, 0, 0, 1);
}

.btn.btn-usfc-primary {
  background-color: #3699ff;
  color: white;
  border-radius: 6px;
  padding: 10px 25px;
}

.btn-usfc-primary:hover {
  background-color: #3699ff;
  color: white;
  box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
}

.label-float input {
  border: none;
  background-color: whitesmoke;
  border-radius: 5px;
  outline: none;
  min-width: 250px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  top: 10px;
}

.label-float select {
  border-left: 2px solid rgba(0, 0, 0, 0.15);
  background-color: transparent;
  outline: none;
  margin-left: 20px;
  padding: 10px 20px;
  font-size: 14px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  position: absolute;
  left: -120px;
  cursor: pointer;
}

.label-float input:focus {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float select:hover {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float select:focus {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float input::placeholder {
  color: black;
}

.label-float select::placeholder {
  color: black;
}

.label-float label {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 8px);
  left: 15px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  background-color: transparent;
  padding: 5px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.5);
}

.label-float input:required:invalid + label {
  color: red;
}
.label-float input:focus:required:invalid {
  border: 2px solid red;
}
.label-float input:required:invalid + label:before {
  content: "*";
}
.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label {
  font-size: 13px;
  top: 10px;
  color: #3951b2;
  opacity: 1;
  background-color: transparent;
}

.label-float select:required:invalid + label {
  color: red;
}
.label-float select:focus:required:invalid {
  border: 2px solid red;
}
.label-float select:required:invalid + label:before {
  content: "*";
}

label.option-selected {
  font-size: 13px;
  transform: translateY(calc(-55%));
  color: #3951b2;
  background-color: transparent;
}
</style>
